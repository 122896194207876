.table-student h1{
   color:red;
 }
 .table-student .imgStudentInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
 }
  .table-student .stuHeader {
     background-color: #327A81;
     color: white;
     font-size: 1.5em;
     padding: 1rem;
     text-align: center;
   }
  .table-student .imgStudentInfo img {
     border-radius: 50%;
     margin-left: 10px;
     height: 200px;
     width: 200px;
   }
   .table-student .imgStudentInfo .myName{
      margin-top: 10%;
  }
  .table-student .imgStudentInfo .myName>*{
    padding: 1%;
  }
   .table-student {
     background-color: #d6e0f0;
     border: 1px solid #8d93ab;
     border-radius: 10px;
     box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
     max-width: calc(100% - 2em);
     margin: 1em auto;
     overflow: hidden;
     width: 800px;
   }
   .table-student table {
     width: 100%;
   }
   .table-student table td, .table-student table th {
     color: #2b686e;
     padding: 10px;
   }
   .table-student table td {
     text-align: center;
     vertical-align: middle;
   }
   .table-student table th {
     background-color: #f4f4f2;
     font-weight: 500;
   }
   .table-student table tr:nth-child(2n) {
     background-color: #e8e8e8;
   }
   .table-student table tr:nth-child(2n+1) {
     background-color: white;
   }
   .table-student .MuiSvgIcon-root{
    color:green;
 }
 .table-student .x{
  color:red;
 }
 .table-student .primary{
  color:#3F51B5;
 }
 .table-student .cancel{
  position: absolute; 
  color:black;
  height: 40px;
  width: 40px;
 }
 .table-student .cancel :hover{
   cursor: pointer;
 }
 .table-student .edit-attendance-row{
  display: grid;
  grid-template-columns: 20px 40px;
  margin-left: 37%;
 }
 .table-student .edit-attendance-row *{
  margin-left: 10px;
 }
 .table-student .edit-attendance-row .att1{
  display:none;
  grid-template-columns: 20px 20px;
 }
 .table-student .edit-attendance-row .att2{
  display:grid;
  grid-template-columns: 20px 20px;
 }
 .table-student .edit-attendance-row .att3{
  display:none;
  grid-template-columns: 20px 20px;
 }
 @media screen and (max-width: 500px) {
    .table-student .imgStudentInfo{
       grid-template-columns: 1fr;
       margin-left: 10%;
    }
    table .hideCol{
       display: none;
    }
 }
