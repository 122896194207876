.table-meeting .header {
    background-color: #327a81;
    color: white;
    font-size: 1.5em;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
  }
  .table-meeting img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }
  .table-meeting .MuiSvgIcon-root:hover{
     cursor: pointer;
  }
  .table-meeting {
    background-color: #92CFD4;
    border: 1px solid #327a81;
    border-radius: 10px;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
    max-width: calc(100% - 2em);
    margin: 1em auto;
    overflow: hidden;
    width: 800px;
  }
  .table-meeting table {
    width: 100%;
  }
  .table-meeting table td,.table-meeting table th {
    color: #2b686e;
    padding: 10px;
  }
  .table-meeting table td {
    text-align: center;
    vertical-align: middle;
  }
  .table-meeting table td:last-child {
    font-size: 0.95em;
    line-height: 1.4;
  }
  .table-meeting table th {
    background-color: #daeff1;
    font-weight: 300;
  }
  .table-meeting table tr:nth-child(2n) {
    background-color: white;
  }
  .table-meeting table tr:nth-child(2n+1) {
    background-color: #edf7f8;
  }
  .MuiSvgIcon-root{
     color:green;
  }
  .x{
   color:red;
}
.table-meeting>table>tr>td .ddd {
   display: grid;
   grid-template-columns: 60px 60px 60px;
   margin-left: 100px;
}
@media screen and (max-width: 601px) {
    .table-meeting>table>tr>td .ddd {
      margin-left: 0px;
   }
}
/* @media screen and (max-width: 601px) {
    .table-meeting table,.table-meeting tr,.table-meeting td {
      display: block;
   }
   .table-meeting td:first-child {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100px;
   }
   .table-meeting td:not(:first-child) {
      clear: both;
      margin-left: 100px;
      padding: 4px 20px 4px 90px;
      position: relative;
      text-align: left;
   }
   .table-meeting td:not(:first-child):before {
      color: #91ced4;
      content: '';
      display: block;
      left: 0;
      position: absolute;
   }
   .table-meeting td:nth-child(1):before {
      content: 'Student Id:';
   }
   .table-meeting td:nth-child(2):before {
      content: 'Name:';
   }
   .table-meeting td:nth-child(3):before {
      content: 'Attendance Meeting:';
   }
   .table-meeting tr {
      padding: 10px 0;
      position: relative;
   }
   .table-meeting tr:first-child {
      display: none;
   }
  }

  @media screen and (max-width: 500px) {
    .table-meeting .header {
      background-color: transparent;
      color: #fff;
      font-size: 2em;
      font-weight: 700;
      padding: 0;
      text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
   }
   .table-meeting img {
      border: 3px solid;
      border-color: #daeff1;
      height: 100px;
      margin: 0.5rem 0;
      width: 100px;
   }
   .table-meeting td:first-child {
      background-color: #c8e7ea;
      border-bottom: 1px solid #91ced4;
      border-radius: 10px 10px 0 0;
      position: relative;
      top: 0;
      transform: translateY(0);
      width: 100%;
   }
   .table-meeting td:not(:first-child) {
      margin: 0;
      padding: 5px 1em;
      width: 100%;
   }
   .table-meeting td:not(:first-child):before {
      font-size: 0.8em;
      padding-top: 0.3em;
      position: relative;
   }
   .table-meeting td:last-child {
      padding-bottom: 1rem !important;
   }
   .table-meeting tr {
      background-color: white !important;
      border: 1px solid #6cbec6;
      border-radius: 10px;
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
      margin: 0.5rem 0;
      padding: 0;
   }
   .table-meeting {
      border: none;
      box-shadow: none;
      overflow: visible;
   }
  }  */