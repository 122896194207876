
.student-nvbar{
    text-align: center;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .student-nvbar #student-navlinks {
    list-style-type: none;
    margin: auto;
    padding: 0;
    overflow: hidden;
    background-color:#327A81;
    font-family:  'Carter One', cursive;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    width: 101%;
  }
  
  .student-nvbar #student-navlinks .navLinksLi {
    float: left;
    border-right:1px solid #bbb;
  }
  
  .student-nvbar #student-navlinks .navLinksLi:last-child {
    border-right: none;
  }
  
  .student-nvbar #student-navlinks .navLinksLi a {
    display: block;
    color: black;
    text-align: center;
    padding: 16px 16px;
    text-decoration: none;
  }
  .student-nvbar #student-navlinks .navLinksLi>a:hover{
    color: #FFFFFF;
  }
  .student-nvbar #student-navlinks .navLinksLi a:hover:not(.active) {
    background-color: #9ad3bc;
  }
  .student-nvbar .toggleNavbar {
    cursor: pointer;
    display: none;
    background-color:#327A81;
    color: black;
    text-align: left;
    padding: 16px 16px;
    text-decoration: none;
    width: 100%;
    font-size: 30px;
  }
  @media only screen and (max-width: 601px) {
    .student-nvbar #student-navlinks .navLinksLi {
      float:none;
    }
    .student-nvbar .toggleNavbar {
      display: block;
    }
  }

  @media only screen and (min-width: 601px) {
    .student-nvbar #student-navlinks{
      display: block;
    }
  }

  .showNavbar {
    display: none;
  }