@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";
body{
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* background: url(1.jpg) no-repeat; */
  /* background-size: cover; */
}
.reg-box{
  width: 280px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); */
  margin: auto;
  color: white;
}
.reg-box h1{
  float: left;
  font-size: 40px;
  border-bottom: 6px solid #327A81;
  margin-bottom: 50px;
  padding: 13px 0;
}
.reg-box .textbox{
  width: 100%;
  overflow: hidden;
  font-size: 20px;
  padding: 8px 0;
  margin: 8px 0;
  border-bottom: 1px solid #327A81;
}
.reg-box .textbox i{
  width: 26px;
  float: left;
  text-align: center;
}
.reg-box .textbox input{
  border: none;
  outline: none;
  background: none;
  color: white;
  font-size: 18px;
  width: 80%;
  float: left;
  margin: 0 10px;
}
.reg-box .btn{
  width: 100%;
  background: none;
  border: 2px solid #327A81;
  color: white;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  margin: 12px 0;
}
.reg-box .btna{
  width: 100%;
  background: none;
  color: white;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  margin: 12px 0;
  margin-left: 40px;
}
.reg-box .btna :hover{
  cursor: pointer;
}
.reg-box .warnings{
    /* border: 3px solid #327A81; */
    /* border-radius: 5px; */
    /* background-color: rgb(148, 200, 150);  */
    opacity: 0.9;
    margin-top: 10px;
    display: none;
  }

/* for labels---------------------------------------------------------------------- */
.reg-box .labels{
  margin-top: 5%;
  margin-bottom: 5%;
}
.reg-box .container {
  /* display: block; */
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  margin-right: 5%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.reg-box .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.reg-box .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.reg-box .container:hover input ~ .checkmark {
  background-color: #ccc;
} 

/* When the radio button is checked, add a blue background */
.reg-box .container input:checked ~ .checkmark {
  background-color:#327A81 ;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.reg-box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.reg-box .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.reg-box .container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}