.main-msg{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

}
.main-msg .msg-content{
    /* background-color: #92CFD4; */
    /* border: 1px solid #327a81; */
    border-radius: 10px;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
    max-width: calc(100% - 2em);
    margin:auto;
    margin-top: 2%;
    overflow: hidden;
    width: 80%;
}
.msg-content h2{
    background-color: #327a81;
    color: white;
    font-size: 1.5em;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.msg-content table {
    width: 100%;
  }
  .msg-content table td,.msg-content table th {
    color: #2b686e;
    padding: 10px;
  }
  .msg-content table td {
    text-align: center;
    vertical-align: middle;   
  }
  .msg-content table th {
    background-color: #daeff1;
    font-weight: 300;
  }
  .msg-content table tr:nth-child(2n) {
    background-color: white;
  }
  .msg-content table tr:nth-child(2n+1) {
    background-color: #edf7f8;
  }
  .msg-content table #del-href{
    padding: 5px 25px;
    background-color: red;
    border-radius: 6px;
    color: white;
    border: 2px solid red;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  .msg-content table #del-href:hover{
    background: none;
    color:red;
  }
  @media screen and (max-width: 750px) {
    .msg-content table tr td{
      display: block;
   }
   .msg-content table tr:first-child {
    display: none;
 }
   .msg-content td:not(:first-child) {
      clear: both;
      margin-left: 2%;
      position: relative;
      text-align: left;
   }
   .msg-content td:not(:first-child):before {
      color: #91ced4;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      font-weight: 600;
   }
   .msg-content td:nth-child(2):before {
      content: 'Student ID:';
   }
   .msg-content td:nth-child(3):before {
      content: 'Email';
   }
   .msg-content td:nth-child(4):before {
      content: 'Subject:';
   }
   .msg-content td:nth-child(5):before {
      content: 'Message:';
   }
   .msg-content td:nth-child(6){
    float: right;
   }
   .msg-content td:first-child {
    background-color: #c8e7ea;
    border-bottom: 1px solid #91ced4;
    font-weight: 600;
 }
 .msg-content td:not(:first-child) {
    margin: 0;
    padding: 5px 1em;
    width: 100%;
 }
 .msg-content td:nth-child(6){
  float: right;
  width: auto;
 }
 .msg-content td:not(:first-child):before {
    font-size: 0.8em;
    padding-top: 0.3em;
    position: relative;
 }
 .msg-content td:last-child {
    padding-bottom: 1rem !important;
 }
  } 